.Button {
  border-radius: 3px;
  padding: 5px 20px;
  font-family: 'Nunito Sans', sans-serif;
}

.Button:hover {
  cursor: pointer;
}

.Back {
  margin-top: 10px;
  margin-bottom: 60px;
}

.BorderCountries {
  padding: 3px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
