.Navigation {
  box-sizing: border-box;
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  z-index: 100;
}

@media (min-width: 1024px) {
  .Navigation {
    padding: 0 48px;
  }
}
