.SearchFilter {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto 25px auto;
}

.Input {
  position: relative;
}

.SearchOptions {
  box-sizing: border-box;
  list-style: none;
  border-radius: 5px;
  padding: 15px 0;
  width: 100%;
  max-width: 400px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 90px;
  z-index: 100;
}

.SearchOptions li {
  padding: 5px 50px;
  font-weight: 600;
}

.SearchOptions li:hover {
  cursor: pointer;
}

@media (min-width: 750px) {
  .SearchFilter {
    padding: 10px 45px;
    flex-direction: row;
    justify-content: space-between;
  }
}
