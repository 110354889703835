.Switcher {
  font-weight: 300;
  padding: 10px;
  border: none;
}

.Switcher:hover {
  cursor: pointer;
}

.Switcher .icon {
  margin-right: 10px;
}
