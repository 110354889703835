.Search {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.Icon {
  padding: 0 20px;
}

.Search input {
  padding: 15px 0;
  width: 100%;
  outline: none;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (min-width: 600px) {
  .Search {
    width: 400px;
  }
}
