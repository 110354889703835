.FullCountry {
  color: hsl(200, 15%, 8%);
  padding: 30px 20px;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
}

.Icon {
  color: hsl(200, 15%, 8%);
  margin-right: 10px;
}

.CountryDetails,
.Info {
  display: flex;
  flex-direction: column;
}

.CountryDetails img {
  min-width: 260px;
  width: 100%;
}

@media (min-width: 768px) {
  .CountryDetails img {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .FullCountry {
    padding: 50px 48px 60px 48px;
  }

  .CountryDetails {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .CountryDetails img {
    min-width: 350px;
    width: 50%;
  }

  .CountryInfo {
    display: flex;
    flex-direction: column;
    padding: 0 80px;
    width: 50%;
    max-width: 450px;
  }

  .Info {
    flex-direction: row;
  }

  .column:first-child {
    margin-right: 80px;
  }

  .BorderCountries div {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .BorderCountries h3 {
    float: left;
    font-size: 14px;
    margin-right: 10px;
  }
}
