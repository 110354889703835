.Countries {
  padding: 20px 45px;
  display: grid;
  gap: 0 40px;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-template-rows: auto;
  justify-items: center;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
}

@media (min-width: 660px) {
  .Countries {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}

@media (min-width: 1280px) {
  .Countries {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}
