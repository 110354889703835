.Country {
  width: 100%;
  margin-bottom: 50px;
  border-radius: 8px;
}

.Image img {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.Info {
  padding: 15px 20px;
}
